var nameGenerator = new Vue({
    el: '#name-generator-form',
    data: {
        section1: {
            q1: {
                question: 'Roll a d6. Use this table to determine how many names you have:',
                value: null,
            }
        },
        section2: {
            q1: {
                question: 'What is your full name?',
                type: 'text',
                placeholder: 'Full Name',
                value: null
            },
            q2: {
                question: 'What do you do at your occupation?',
                type: 'textarea',
                placeholder: '(Optional) Full Description',
                optional: true,
                value: null,
                subquestions: {
                    a: {
                        question: 'Condense the verb in your answer into a single -er noun. (e.g. if youare a sheep farmer, your answer will be “farmer”)',
                        type: 'text',
                        placeholder: 'Single -er noun',
                        value: null,
                    }
                }
            },
            q3: {
                question: 'What was your first pet’s specific species/breed? If you never had a pet, please answer with an animal you wish you owned.',
                type: 'text',
                placeholder: 'First Pet\'s Species/Breed',
                value: null
            },
            q4: {
                question: 'What’s your most embarrassing childhood memory? Be specific.',
                type: 'textarea',
                placeholder: '(Optional) Embarrassing Childhood Memory',
                optional: true,
                value: null,
                subquestions: {
                    a: {
                        question: 'Condense this story into two words.',
                        type: 'text',
                        placeholder: 'Condensed to Two Words',
                        value: null
                    }
                }
            },
            q5: {
                question: 'What is the object you’d least like to be stabbed by?',
                type: 'text',
                placeholder: 'Object',
                value: null
            },
            q6: {
                question: 'What is something you are good at? (Verb ending in -ing)',
                type: 'text',
                placeholder: 'Skill',
                value: null
            },
            q7: {
                question: 'How many carrots do you believe you could eat in one sitting, if someone, like, forced you to eat as many carrots as possible?',
                type: 'number',
                placeholder: 'Number of Carrots',
                optional: true,
                value: null
            },
            q8: {
                question: 'What is your greatest intangible fear? (e.g. death, loneliness, fear itself)',
                type: 'text',
                placeholder: 'Intangible Fear',
                value: null
            },
            q9: {
                question: 'What is your greatest tangible fear? (e.g. horses)',
                type: 'text',
                placeholder: 'Tangible Fear',
                value: null
            },
            q10: {
                question: 'What is the last thing you did before starting this worksheet?',
                type: 'text',
                placeholder: 'Last Thing',
                value: null
            },
            q11: {
                question: 'What condition is your body currently in? (single word answer)',
                type: 'text',
                placeholder: 'Body Condition',
                value: null
            },
            q12: {
                question: 'Favorite state of matter?',
                type: 'text',
                placeholder: 'Solid, Liquid, Gas, etc.',
                datalist: ['Solid', 'Liquid', 'Gas', 'Plasma'].sort(),
                value: null
            },
            q13: {
                question: 'A word your name kind of sounds like? (e.g. Brian -> Brain)',
                type: 'text',
                placeholder: 'Name Homonym',
                value: null
            },
            q14: {
                question: 'What is your Zodiac sign?',
                type: 'text',
                placeholder: 'Zodiac Sign',
                value: null
            },
            q15: {
                question: 'If you had to define your personality in one word, what would it be?',
                type: 'text',
                placeholder: 'Personality',
                value: null
            }
        },
        section3: {
            q16: {
                question: 'Who is your favorite film character? (NOTE: must be played by Kurt Russell)',
                type: 'text',
                placeholder: 'Favorite Film Character',
                datalist: ['Adult Copper', 'Bart', 'Ben Crane', 'Boy Kicking Mike', 'Captain Ives', 'Captain Ron', 'Coach Hand', 'Col. Jonathan "Jack" O\'Neil', 'Crunch Calhoun', 'Dad', 'David Grant', 'Dean Proffitt', 'Dexter Riley', 'Drew Stephens', 'Ego the Living Planet', 'Eldon Perry', 'Elvis Presley', 'Herb Brooks', 'Himself', 'Jack Burton', 'Jamie McPheeters', 'Jeff Taylor', 'Jimmy "Mr. Jimmy" Harrell', 'John "The Hangman" Ruth', 'Johnny Jesus', 'Lt. Gabriel Cash', 'Lucky Lockhart', 'Malcolm Anderson', 'Martin Duran, Sr.', 'McCabe', 'Michael Carr', 'Michael Zane', 'Morgan "Two Persons" Bodeen', 'Mr. Nobody', 'Nick Frescia', 'R.J. MacReady', 'Randy and the Narrator', 'Ray Ferris', 'Reno Hightower', 'Robert Ramsey', 'Ronnie Gardner', 'Rudy Russo', 'Santa Claus / Saint "Nick\' Nicholas', 'Sheriff Franklin Hunt', 'Sidney Bower', 'Snake Plissken', 'Stephen McCaffrey / Dennis McCaffrey', 'Steve Stronghold / The Commander', 'Steven Post', 'Stuntman Mike', 'Todd', 'Wayland Jackson', 'Whitey', 'Willie Prentiss', 'Wyatt Earp'],
                value: null,
            },
            q17: {
                question: 'What is the last word of the title of your favorite Kubrick film?',
                type: 'text',
                placeholder: 'Last Word',
                datalist: ['Desire', 'Fight', 'Glory', 'Jacket', 'Killing', 'Kiss', 'Lolita', 'Lyndon', 'Odyssey', 'Orange', 'Padre', 'Seafarers', 'Shining', 'Shut', 'Spartacus', 'Strangelove'],
                value: null,
            },
            q18: {
                question: 'What is the first word in the title of your favorite Joy Division album?',
                type: 'text',
                placeholder: 'First Word',
                datalist: ['Atmosphere', 'Best', 'Ceremony', 'Closer', 'Complete', 'Earcom', 'Factory', 'Heart', 'Ideal', 'Komakino', 'Les', 'Licht', 'Love', 'Peel', 'Permanent', 'Preston', 'Short', 'Singles', 'Still', 'Substance', 'Total', 'Transmission', 'Unknown'],
                value: null,
            },
            q19: {
                question: 'What is a scientific term you picked up from listening to NPR once?',
                type: 'text',
                placeholder: 'Scientific Term',
                value: null,
            },
            q20: {
                question: 'What is a piece of military hardware you think looks cool even though war is bad?',
                type: 'text',
                placeholder: 'Military Hardware',
                value: null,
            },
            q21: {
                question: 'What is something you’d enjoy watching Mads Mikkelsen do?',
                type: 'textarea',
                placeholder: '(Optional) Mads\' Actions',
                optional: true,
                value: null,
                subquestions: {
                    a: {
                        question: 'Please condense into one word.',
                        type: 'text',
                        placeholder: 'One Word',
                        value: null
                    }
                }
            }
        },
        section4: {
            q1: {
                question: 'THE -MAN CONDITION: Roll a d4. Use this table to determine whether you have this condition:',
                value: {
                    normal: null,
                    occupational: null,
                    horny: null,
                    the: null,
                    cool: null,
                    violent: null,
                    subtext: null,
                },
                diceSides: 4,
                icon: 'fa-dice-d4',
                results: [
                    ['1-3', 'You do not have this condition.'],
                    ['4', 'You have this condition. Your last name will include the suffix -man. If your name already has -man at the end of it, I guess you’re just going to have -manman at the end of your name.']
                ]
            },
            q2: {
                question: 'THE CONDITION CONDITION: Roll a d8. Use this table to determine your what this condition will do to you:',
                value: {
                    normal: null,
                    occupational: null,
                    horny: null,
                    the: null,
                    cool: null,
                    violent: null,
                    subtext: null,
                },
                diceSides: 8,
                icon: 'fa-dice-d8',
                results: [
                    ['1-5', 'You do not have this condition.'],
                    ['6', 'You’re big. Your name must have “Big” at the beginning of it.'],
                    ['7', 'You are older than you once were. Your name must have “Old” at the beginning of it.'],
                    ['8', 'You are how you currently are. Please add your answer from Section2, Number 11 to the beginning of your name.']
                ]
            },
            q3: {
                question: 'THE CLONE CONDITION: Roll a d12. Use this table to determine if you have this condition:',
                value: {
                    normal: null,
                    occupational: null,
                    horny: null,
                    the: null,
                    cool: null,
                    violent: null,
                    subtext: null,
                },
                diceSides: 12,
                icon: 'fa-dice-d12',
                results: [
                    ['1-11', 'You do not have this condition.'],
                    ['12', 'You are a clone of someone else, or you have been brainwashed into becoming a mental doppelganger of someone else. Find someone who has completed this worksheet and replace 50% of your Kojima name with 50% of their Kojima name.']
                ]
            },
            q4: {
                question: 'THE KOJIMA CONDITION: Roll a d100 (or two d10s, using one as the 1s place and the other as the 10s place.) Use this table to determine if you have this condition:',
                value: {
                    normal: null,
                    occupational: null,
                    horny: null,
                    the: null,
                    cool: null,
                    violent: null,
                    subtext: null,
                },
                diceSides: 100,
                icon: 'fa-dice-d10',
                results: [
                    ['1-68, 70-100', 'You do not have this condition.'],
                    ['69', 'Oh no. You are Hideo Kojima. Hideo Kojima created you and is also you. You are the man who created himself and there is nothing you can do about it. You’re in Kojima’s world — your world — and that’s just the breaks, pal. Stop this worksheet now. You’re Hideo Kojima. Go do the things that Hideo Kojima does.']
                ]
            }
        },
        section5: {
            q1: {
                question: 'Roll a d20. Use this table to determine your name category. Once you have figured out your name category, you can skip ahead to the section that outlines the rules of your name:',
                value: null,
                diceSides: 20,
                icon: 'fa-dice-d20',
                results: [
                    ['1', 'You have a NORMAL NAME (See Section 6)'],
                    ['2-6', 'You have an OCCUPATIONAL NAME (See Section 7)'],
                    ['7-10', 'You have a HORNY NAME (See Section 8)'],
                    ['11-13', 'You have a THE NAME (See Section 9)'],
                    ['14-17', 'You have a COOL NAME (See Section 10)'],
                    ['18-19', 'You have a VIOLENT NAME (See Section 11)'],
                    ['20', 'You have a NAME THAT LACKS SUBTEXT (See Section 12)']
                ]
            }
        },
        section6: {

        },
        section7: {
            q2: {
                question: 'Roll a d4. Use this table to determine your first name:',
                value: null,
                diceSides: 4,
                icon: 'fa-dice-d4',
                results: [
                    ['1', 'Your answer to Section 2, Number 15'],
                    ['2', 'Your answer to Section 2, Number 6'],
                    ['3', 'Your answer to Section 2, Number 13'],
                    ['4', 'Your answer to Section 3, Number 16']
                ]
            }
        },
        section8: {
            q2: {
                question: 'Roll a d4. Use this table to determine your first name:',
                value: null,
                diceSides: 4,
                icon: 'fa-dice-d4',
                results: [
                    ['1', 'Your answer to Section 2, Number 12'],
                    ['2', 'Your first name is “Naked.”'],
                    ['3', 'Your answer to Section 2, Number 6'],
                    ['4', 'Your answer to Section 2, Number 14']
                ]
            },
            q3: {
                optional: true,
                value: null
            }
        },
        section9: {
            q2: {
                question: 'Roll a d4. Use this table to determine your last name:',
                value: null,
                diceSides: 4,
                icon: 'fa-dice-d4',
                results: [
                    ['1', 'Your answer to Section 2, Number 8'],
                    ['2', 'Your answer to Section 2, Number 9'],
                    ['3', 'Your answer to Section 2, Number 4a'],
                    ['4', 'Your answer to Section 3, Number 20']
                ]
            }
        },
        section10: {
            q2: {
                question: 'Roll a d6. Use this table to determine your last name:',
                value: null,
                diceSides: 6,
                icon: 'fa-dice-d6',
                results: [
                    ['1', 'Your answer to Section 3, Number 17'],
                    ['2', 'Your answer to Section 3, Number 18'],
                    ['3', 'Your answer to Section 3, Number 19'],
                    ['4', 'Your answer to Section 2, Number 6'],
                    ['5', 'Your answer to Section 2, Number 8'],
                    ['6', 'Your answer to Section 2, Number 13']
                ]
            }
        },
        section11: {
            q2: {
                question: 'Roll a d4. Use this table to determine your first name:',
                value: null,
                diceSides: 4,
                icon: 'fa-dice-d4',
                results: [
                    ['1', 'Your answer to Section 3, Number 19'],
                    ['2', 'Your answer to Section 2, Number 12'],
                    ['3', 'Your answer to Section 3, Number 20'],
                    ['4', 'Your answer to Section 2, Number 9']
                ]
            }
        },
    },
    computed: {
        validated: function() {
            return !this.comboName
        },
        normalName: function() {
            return this.applySec4Conditions(this.section2.q1.value, 'normal');
        },
        noSubtextName: function() {
            return this.applySec4Conditions(this.section2.q10.value, 'subtext');
        },
        violentName: function() {
            if (this.section4.q4.value['violent'] == 69) {
                return 'Hideo Kojima';
            }

            var firstName = '';
            var lastName = this.section2.q5.value;

            switch (parseInt(this.section11.q2.value)) {
                case 1:
                    firstName = this.section3.q19.value;
                    break;
                case 2:
                    firstName = this.section2.q12.value;
                    break;
                case 3:
                    firstName = this.section3.q20.value;
                    break;
                case 4:
                    firstName = this.section2.q9.value;
                    break;
            }

            return firstName + ' ' + lastName;
        },
        coolName: function() {
            var firstName = this.section3.q21.subquestions.a.value;
            var lastName = '';

            switch (parseInt(this.section10.q2.value)) {
                case 1:
                    lastName = this.section3.q17.value;
                    break;
                case 2:
                    lastName = this.section3.q18.value;
                    break;
                case 3:
                    lastName = this.section3.q19.value;
                    break;
                case 4:
                    lastName = this.section2.q6.value;
                    break;
                case 5:
                    lastName = this.section2.q8.value;
                    break;
                case 6:
                    lastName = this.section2.q13.value;
                    break;
            }

            return this.applySec4Conditions(firstName + ' ' + lastName, 'cool');
        },
        theName: function() {
            if (this.section4.q4.value['the'] == 69) {
                return 'Hideo Kojima';
            }


            var lastName = '';
            var firstName = 'The ';

            switch (parseInt(this.section9.q2.value)) {
                case 1:
                    lastName = this.section2.q8.value;
                    break;
                case 2:
                    lastName = this.section2.q9.value;
                    break;
                case 3:
                    lastName = this.section2.q4.subquestions.a.value;
                    break;
                case 4:
                    lastName = this.section3.q20.value;
                    break;
            }

            return firstName + this.applySec4Conditions(lastName);
        },
        hornyName: function() {
            var lastName = this.section2.q3.value;
            var middleName = this.section8.q3.value ? ' Lickable ' : ' ';
            var firstName = '';

            switch (parseInt(this.section8.q2.value)) {
                case 1:
                    firstName = this.section2.q12.value;
                    break;
                case 2:
                    firstName = 'Naked';
                    break;
                case 3:
                    firstName = this.section2.q6.value;
                    break;
                case 4:
                    firstName = this.section2.q14.value;
                    break;
            }

            return this.applySec4Conditions(firstName + middleName + lastName, 'horny');

        },
        occupationalName: function() {
            var lastName = this.section2.q2.subquestions.a.value;
            var firstName = '';

            switch (parseInt(this.section7.q2.value)) {
                case 1:
                    firstName = this.section2.q15.value;
                    break;
                case 2:
                    firstName = this.section2.q6.value;
                    break;
                case 3:
                    firstName = this.section2.q13.value;
                    break;
                case 4:
                    firstName = this.section3.q16.value;
                    break;
            }

            return this.applySec4Conditions(firstName + ' ' + lastName, 'occupational');
        },

        showNormalName: function() {
            return section1.q1.value == 6;
        }
    },
    methods: {
        rollDie: function(sides, ignore) {
            var roll = 0;
            do {
                roll = Math.floor(Math.random() * sides + 1);
                console.log(roll);
            } while (roll == ignore);
            return roll;
        },
        rollDieDict: function(sides, dict) {
            console.log('Rolling');
            var keys = Object.keys(dict);
            keys.forEach(key => {
                dict[key] = this.rollDie(sides, dict[key]);
            });
            return dict;
        },
        rollDieDictSame: function(sides, dict) {
            console.log('Rolling Same');
            var keys = Object.keys(dict);
            var rollValue = this.rollDie(sides, dict[keys[0]]);
            keys.forEach(key => {
                dict[key] = rollValue;
            });
            return dict;
        },
        updateAllValues: function(dict, baseKey) {
            var keys = Object.keys(dict);
            keys.forEach(key => {
                dict[key] = dict[baseKey]
            });
            return dict;
        },
        applySec4Conditions: function(name, nameType) {
            if (parseInt(this.section4.q1.value[nameType]) === 4) {
                name += 'man';
            }

            var space = name === 'man' ? '' : ' ';

            switch (parseInt(this.section4.q2.value[nameType])) {
                case 6:
                    name = 'Big' + space + name;
                    break;
                case 7:
                    name = 'Old' + space + name;
                    break;
                case 8:
                    name = this.section2.q11.value + space + name;
            }

            if (parseInt(this.section4.q4.value[nameType]) === 69) {
                name = 'Hideo Kojima';
            }

            return name;
        },

        sectionComplete: function(section) {
            var isComplete = true;
            var keys = Object.keys(section);
            return keys.every(key => {
                // console.log(`Checking key: ${key}; Value: ${JSON.stringify(section[key].value)}`);
                var question = section[key];

                // Questions that have subquestions
                if (question.hasOwnProperty('subquestions')) {
                    if (!this.sectionComplete(question.subquestions)) {
                        return false;
                    }
                }
                // Section 4 questions
                else if (question.value && question.value.hasOwnProperty('normal')) {
                    console.log('Checking Section 4');
                    var sec4Keys = Object.keys(question.value);
                    return sec4Keys.every(sec4Key => question.value[sec4Key]);
                }
                // Questions that just have regular values
                else if (!question.value && !question.optional) {
                    return false;
                }
                return true;
            });
        },
    }
});